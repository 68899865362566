import React from 'react';
import Layout from '../components/CategoryLayout';
import { Button } from '@material-ui/core';

export default function NotFoundPage(): JSX.Element {
  return (
    <Layout>
      <h2>Una disculpa, esta página no existe</h2>
      <p>404. Página no encontrada</p>
      <Button
        style={{ color: 'white' }}
        color="secondary"
        variant="contained"
        href="/"
      >
        Regresar a inicio
      </Button>
    </Layout>
  );
}
